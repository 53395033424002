import React from "react";
import logo from "./logo.svg";
import "./App.css";
import ReactCursorPosition, { INTERACTIONS } from "react-cursor-position";

const theme = {
  darkGreen: "#29382F",
  lightGreen: "#99A38D"
};

const Headline = props => {
  console.log(props.elementDimensions);
  let view = { width: 425, height: 300 };
  let hardCodedPathLength = 500;
  let hardCodedEnd = 135;
  hardCodedPathLength = hardCodedPathLength - hardCodedEnd;
  let ratio = hardCodedPathLength / props.elementDimensions.width;

  let positionX = props.position.x * ratio;

  console.log(positionX);

  return (
    <React.Fragment>
      <pre style={{ margin: 0, padding: 0 }}>
        {props.position.x}, {props.position.x}
      </pre>
      <pre>{positionX}</pre>
      <svg
        viewBox={`0 0 ${view.width} ${view.height}`}
        style={{
          fill: theme.lightGreen
        }}
      >
        <path
          id="curve"
          fill="transparent"
          d="M6,150C49.63,93,105.79,36.65,156.2,47.55,207.89,58.74,213,131.91,264,150c40.67,14.43,108.57-6.91,229-145"
        />
        <text x={Math.min(positionX)}>
          <textPath xlinkHref="#curve">coming soooooooon…</textPath>
        </text>
      </svg>
    </React.Fragment>
  );
};

function App() {
  return (
    <div className="App">
      <ReactCursorPosition
        activationInteractionTouch={INTERACTIONS.TOUCH}
        style={{
          cursor: "pointer",
          maxWidth: "100%",
          height: "100%",
          margin: "1rem",
          color: "white",
          backgroundColor: theme.darkGreen
        }}
      >
        <Headline />
      </ReactCursorPosition>
    </div>
  );
}

export default App;
